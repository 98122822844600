@font-face {
  font-family: 'Butler Bold';
  src: url('./assets/fonts/Butler/Butler-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'SegoeUi Bold';
  src: url('./assets/fonts/SegoeUi/SegoeUi-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'SegoeUi SemiBold';
  src: url('./assets/fonts/SegoeUi/SegUi-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter VariableFont';
  src: url('./assets/fonts/Inter/Inter-VariableFont.ttf') format('truetype');
}

@font-face {
  font-family: 'JosefinSans SemiBold';
  src: url('./assets/fonts/JosefinSans/JosefinSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter Bold';
  src: url('./assets/fonts/Inter/Inter-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'JosefinSans Bold';
  src: url('./assets/fonts/JosefinSans/JosefinSans-Bold.ttf') format('truetype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter VariableFont', 'Butler Bold', 'SegoeUi Bold', 'SegoeUi SemiBold', 'Josefin Sans SemiBold',
    'Inter Bold', 'Josefin Sans Bold' -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
